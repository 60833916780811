import React, { useState, useEffect } from "react";
import axios from "axios";

const RisersFallers = () => {
  const [risers, setRisers] = useState([]);
  const [fallers, setFallers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the player data from your API
    axios
      .get("/api/stats/risers")
      .then((response) => {
        const playersData = response.data.data;

        // Calculate the difference between current and previous rankings
        const updatedPlayers = playersData.map((player) => {
          const rankDifference = player.diff;
          return {
            ...player,
            rankDifference: rankDifference.toFixed(2), // Limit to 2 decimal places
          };
        });

        // Separate into risers and fallers
        const risersList = updatedPlayers
          .filter((player) => player.rankDifference > 0)
          .sort((a, b) => b.rankDifference - a.rankDifference);
        const fallersList = updatedPlayers
          .filter((player) => player.rankDifference < 0)
          .sort((a, b) => a.rankDifference - b.rankDifference);

        setRisers(risersList);
        setFallers(fallersList);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Risers and Fallers</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "45%" }}>
          <h3>Risers</h3>
          <ul>
            {risers.map((player) => (
              <li key={player.player}>
                {player.player} - {player.team} - {player.position} - Increased
                by {player.rankDifference} points
              </li>
            ))}
          </ul>
        </div>
        <div style={{ width: "45%" }}>
          <h3>Fallers</h3>
          <ul>
            {fallers.map((player) => (
              <li key={player.player}>
                {player.player} - {player.team} - {player.position} - Dropped by{" "}
                {Math.abs(player.rankDifference)} points
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RisersFallers;
