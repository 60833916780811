// src/components/PlayerDetailsModal.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VorGraph from "./graph";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PlayerDetailsModal = ({ open, onClose, playerData }) => {
  const [selectedVariable, setSelectedVariable] = useState("points_vor");
  const [vorData, setVorData] = useState([]);

  useEffect(() => {
    if (playerData && selectedVariable) {
      // Fetch data for the selected player and variable
      axios
        .get(
          `/api/players/${encodeURIComponent(
            playerData.player
          )}/${selectedVariable}`
        )
        .then((response) => {
          setVorData(response.data);
        });
    }
  }, [playerData, selectedVariable]);

  const handleVariableChange = (event) => {
    setSelectedVariable(event.target.value);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {playerData.name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ mt: 2 }}>Player: {playerData.player}</Typography>
        <Typography sx={{ mt: 2 }}>Position: {playerData.position}</Typography>
        <Typography sx={{ mt: 2 }}>Team: {playerData.team}</Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="variable-select-label">Variable</InputLabel>
          <Select
            labelId="variable-select-label"
            value={selectedVariable}
            onChange={handleVariableChange}
            label="Variable"
          >
            <MenuItem value="points_vor">VOR</MenuItem>
            <MenuItem value="adp">Yahoo ADP</MenuItem>
            <MenuItem value="position_rank">Position Rank</MenuItem>
            {/* Add more variables as needed */}
          </Select>
        </FormControl>
        <VorGraph data={vorData} dataKey={selectedVariable} />
      </Box>
    </Modal>
  );
};

export default PlayerDetailsModal;
