import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import EmailConfirmation from "./EmailConfirmation";

const Home = () => {
  const [showEmailForm, setShowEmailForm] = useState(false); // State to manage form visibility

  const handleShowEmailForm = () => {
    setShowEmailForm(true);
  };

  return (
    <Container style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h2" gutterBottom>
        Welcome to My Fantasy Draft
      </Typography>
      <Typography variant="h6" gutterBottom>
        Your ultimate tool for managing fantasy football drafts.
      </Typography>
      <Typography gutterBottom>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/draft"
        >
          Example Draft Dashboard
        </Button>
      </Typography>
      <Typography gutterBottom>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleShowEmailForm}
        >
          Create a draft
        </Button>
      </Typography>
      {showEmailForm && (
        <Box sx={{ mt: 4 }}>
          <EmailConfirmation />
        </Box>
      )}
    </Container>
  );
};

export default Home;
