import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Container, TextField, Button, Typography, Box } from "@mui/material";

const EmailConfirmation = () => {
    const [name, setName] = useState(""); // State to manage name input
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate(); // Create navigate function

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };

    const handleNameChange = (e) => {
      setName(e.target.value);
    };

    const handleConfirmEmailChange = (e) => {
      setConfirmEmail(e.target.value);
    };

    const navigateToDraft = async (draft_uuid) => {
      navigate(`/draft/${draft_uuid}`);
    };

    const handleNewDraft = async (email) => {
      try {
        const response = await axios.post("/api/draft", { email, name });
        console.log("New Draft Created:", response.data.draft_id);
        return response.data.draft_id;
      } catch (error) {
        console.error("Error adding player:", error);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setErrorMessage("");
      setSuccessMessage("");

      if (!name) {
        setErrorMessage("Draft name is required");
      } else if (!email || !confirmEmail) {
        setErrorMessage("Both fields are required.");
      } else if (email !== confirmEmail) {
        setErrorMessage("Email addresses do not match.");
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setErrorMessage("Email address is not valid.");
      } else {
        setSuccessMessage("Email addresses confirmed!");
        const draft_id = await handleNewDraft(email);
        await navigateToDraft(draft_id);
        //TODO: send them email with link to their draft
      }
    };

    return (
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Enter Your Email
          </Typography>
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            fullWidth
            label="Confirm Email Address"
            variant="outlined"
            margin="normal"
            value={confirmEmail}
            onChange={handleConfirmEmailChange}
          />
          <TextField
            fullWidth
            label="New Draft Name"
            variant="outlined"
            margin="normal"
            value={name}
            onChange={handleNameChange}
            /*required*/
          />
          {errorMessage && (
            <Typography variant="body2" color="error" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body2" color="success" gutterBottom>
              {successMessage}
            </Typography>
          )}
          <Button variant="contained" color="primary" type="submit">
            Create my draft
          </Button>
        </Box>
      </Container>
    );
};

export default EmailConfirmation;
