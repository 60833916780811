import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Lineup = ({ players }) => {
  const [lineup, setLineup] = useState({
    QB: null,
    RB1: null,
    RB2: null,
    WR1: null,
    WR2: null,
    WR3: null,
    TE: null,
    FLEX: null,
    DST: null,
    K: null,
  });
  const [bench, setBench] = useState([]);
  const { draft_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/myteam/${draft_id}`);
        const load_bench = JSON.parse(response.data.data[0].bench);
        const load_lineup = JSON.parse(response.data.data[0].lineup);
        if (!load_lineup) {
          setLineup({
            QB: null,
            RB1: null,
            RB2: null,
            WR1: null,
            WR2: null,
            WR3: null,
            TE: null,
            FLEX: null,
            DST: null,
            K: null,
          });
        } else {
          setLineup(load_lineup);
        }
        if (load_bench.length > 0) {
          setBench(load_bench);
        } else {
          setBench(players);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [draft_id, players]);

  const handlePositionChange = (player, position) => {
    if (position === "bench") {
      // Move player to the bench
      setBench([...bench, player]);
      // Remove from lineup
      setLineup((prevLineup) => {
        const newLineup = { ...prevLineup };
        Object.keys(newLineup).forEach((key) => {
          if (newLineup[key] && newLineup[key].id === player.id) {
            newLineup[key] = null;
          }
        });
        return newLineup;
      });
    } else {
      // Move player to the selected position
      setLineup((prevLineup) => ({
        ...prevLineup,
        [position]: player,
      }));
      // Remove from bench
      setBench((prevBench) => prevBench.filter((p) => p.id !== player.id));
    }
  };

  // const getState = () => {
  //   console.log(bench);
  //   console.log(lineup);
  // };

  const saveLineup = async () => {
    const benchString = JSON.stringify(bench, null, 2);
    const lineupString = JSON.stringify(lineup, null, 2);

    try {
      await axios.put(`/api/myteam/${draft_id}`, {
        benchString,
        lineupString,
      });
    } catch (error) {
      console.error("Error adding player:", error);
    }
  };

  return (
    <div>
      <h2>My Lineup</h2>
      {/* <button onClick={() => getState()}>getState</button> */}
      <div
        style={{
          width: "99%",
          display: "flex",
          justifyContent: "space-between",
          // alignItems: "center",
          // border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <button
          style={{ float: "right" }}
          onClick={() => {
            saveLineup();
            alert("Draft Saved!");
          }}
        >
          Save Lineup
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            <th>Player</th>
            <th>Team</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {lineup &&
            Object.keys(lineup).map((position, index) => (
              <tr key={index}>
                <td>{position}</td>
                <td>{lineup[position]?.player || "Empty"}</td>
                <td>{lineup[position]?.team || "-"}</td>
                <td>
                  {lineup[position]?.player && (
                    <button
                      onClick={(e) =>
                        handlePositionChange(lineup[position], "bench")
                      }
                    >
                      Send to Bench
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h3>Bench</h3>
      <ul>
        {Array.isArray(bench) &&
          bench.map((player) => (
            <li key={player?.id || Math.random()}>
              {/* Ensure player object exists before accessing properties */}
              {player?.player || "Unknown Player"} - {player?.position || "N/A"}{" "}
              - {player?.team || "N/A"}
              <select
                onChange={(e) => handlePositionChange(player, e.target.value)}
                defaultValue="bench"
              >
                <option value="bench">Bench</option>
                {Object.keys(lineup).map((position) => (
                  <option
                    key={position}
                    value={position}
                    disabled={
                      lineup[position] && lineup[position].id !== player?.id
                    }
                  >
                    {position}
                  </option>
                ))}
              </select>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Lineup;
