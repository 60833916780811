import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
//import DraftDetails from "./draftDetails";
import Home from "./Home";
import Navbar from "./Navigation";
import MyDraft from "./MyTeam";
import Risers from "./Risers";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/draft" element={<Dashboard />} />
        <Route path="/draft/:draft_id" element={<Dashboard />} />
        <Route path="/myteam/:draft_id" element={<MyDraft />} />
        <Route path="/risers-fallers" element={<Risers />} />
      </Routes>
    </Router>
  );
}

export default App;
