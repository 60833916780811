import React, { useState, useEffect } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Queue from "./queue";
import "./dashboard.css";
import PlayerDetailsModal from "./playerDetailsModal";
import { useNavigate, useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";

axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_URL || "http://localhost:5000";

const Dashboard = () => {
  const { draft_id } = useParams();
  const [data, setData] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [pickedPlayers, setPickedPlayers] = useState({});
  const [draftedPlayers, setDraftedPlayers] = useState({});
  const [rankings_dt, setRankingsDate] = useState(null);
  const [filter, setFilter] = useState("");
  const [queue, setQueue] = useState([]);
  //const [page, setPage] = useState(1);
  const [page] = useState(1);
  //const [totalPages, setTotalPages] = useState(1);
  const [hideDrafted, setHideDrafted] = useState(false);
  const [draftTitle, setDraftTitle] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "points_vor",
    direction: "desc",
  });
  const [vorData, setVorData] = useState([]);
  const [modalPlayerDetailsModalOpen, setPlayerDetailsModalOpen] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/players?page=${page}&limit=300&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`
        );
        setData(response.data.data);
        //setTotalPages(response.data.totalPages);
        setRankingsDate(response.data.data[0].rankings_dt);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [page, sortConfig]);

  useEffect(() => {
    if (!draft_id) {
      return;
    }
    const getLogState = async () => {
      try {
        const response = await axios.get(`/api/draft/${draft_id}`);
        const data = response.data;
        let draft_details_json;
        let queue_details_json;
        let picked_details_json;
        if (data.data.length === 0) {
          navigate(`/draft`);
        } else {
          setDraftTitle(data.data[0].draft_name);
          if (data.data[0].draft_details) {
            draft_details_json = JSON.parse(data.data[0].draft_details);
            for (const key in draft_details_json) {
              //console.log(`${key}: ${draft_details_json[key]}`);
              if (draft_details_json[key] === true) {
                handleDraftedPlayers(key);
              }
            }
          }

          if (data.data[0].picked_details) {
            picked_details_json = JSON.parse(data.data[0].picked_details);
            for (const key in picked_details_json) {
              //console.log(`${key}: ${draft_details_json[key]}`);
              if (picked_details_json[key] === true) {
                handlePickedPlayers(key);
              }
            }
          }
          if (data.data[0].queue_details) {
            queue_details_json = JSON.parse(data.data[0].queue_details);
            loadQueue(queue_details_json);
          }
        }
      } catch (error) {
        console.error("Error fetching draft data:", error);
      }
    };

    getLogState();
  }, [draft_id, navigate]);

  const handleRowClick = (player) => {
    setSelectedPlayer(player);
  };

  // const getCurrentState = () => {
  //   console.log("pickedPlayers:", pickedPlayers);
  //   console.log("draftedPlayers:", draftedPlayers);
  //   console.log("drafted_data:", draftedData);
  // };

  const handlePickedPlayers = (playerName) => {
    setPickedPlayers((prev) => {
      const newPickedPlayers = { ...prev };
      if (newPickedPlayers[playerName]) {
        delete newPickedPlayers[playerName];
      } else {
        newPickedPlayers[playerName] = true;
      }

      setDraftedPlayers((prevDraftedPlayers) => {
        const newDraftedPlayers = { ...prevDraftedPlayers };
        if (newDraftedPlayers[playerName]) {
          delete newDraftedPlayers[playerName];
        }
        return newDraftedPlayers;
      });

      setQueue((prevQueue) =>
        prevQueue.filter((queuePlayer) => queuePlayer.player !== playerName)
      );

      return newPickedPlayers;
    });
  };

  const handleDraftedPlayers = (playerName) => {
    setDraftedPlayers((prev) => {
      const newDraftedPlayers = { ...prev };
      if (newDraftedPlayers[playerName]) {
        delete newDraftedPlayers[playerName];
      } else {
        newDraftedPlayers[playerName] = true;
      }

      setPickedPlayers((prevPickedPlayers) => {
        const newPickedPlayers = { ...prevPickedPlayers };
        if (newPickedPlayers[playerName]) {
          delete newPickedPlayers[playerName];
        }
        return newPickedPlayers;
      });

      setQueue((prevQueue) =>
        prevQueue.filter((queuePlayer) => queuePlayer.player !== playerName)
      );

      return newDraftedPlayers;
    });
  };

  const handleClose = () => {
    setSelectedPlayer(null);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = React.useMemo(() => {
    const filterLower = filter.toLowerCase();

    if (filter === "FLEX") {
      return sortedData.filter(
        (player) =>
          player.position?.includes("WR") ||
          player.position?.includes("RB") ||
          player.position?.includes("TE")
      );
    } else if (filter === "WR") {
      return sortedData.filter((player) => player.position?.includes("WR"));
    } else if (filter === "QB") {
      return sortedData.filter((player) => player.position?.includes("QB"));
    } else if (filter === "RB") {
      return sortedData.filter((player) => player.position?.includes("RB"));
    } else if (filter === "TE") {
      return sortedData.filter((player) => player.position?.includes("TE"));
    } else if (filter === "K") {
      return sortedData.filter((player) => player.position?.includes("K"));
    } else if (filter === "DST") {
      return sortedData.filter((player) => player.position?.includes("DST"));
    }
    return sortedData.filter(
      (player) =>
        player.player?.toLowerCase().includes(filterLower) ||
        player.team?.toLowerCase().includes(filterLower) ||
        player.position?.toLowerCase().includes(filterLower)
    );
  }, [sortedData, filter]);

  const draftedData = React.useMemo(() => {
    return filteredData.filter(
      (player) =>
        !(
          hideDrafted &&
          (pickedPlayers[player.player] || draftedPlayers[player.player])
        )
    );
  }, [filteredData, hideDrafted, pickedPlayers, draftedPlayers]);

  const saveCurrentState = async () => {
    const queueString = JSON.stringify(queue, null, 2);
    const draftedPlayersString = JSON.stringify(draftedPlayers, null, 2);
    const pickedPlayersString = JSON.stringify(pickedPlayers, null, 2);
    const draft_details = draftedPlayersString;
    const picked_details = pickedPlayersString;
    const queue_details = queueString;
    const draft_name = draftTitle;

    try {
      await axios.put(`/api/draft/${draft_id}`, {
        draft_name,
        queue_details,
        draft_details,
        picked_details,
      });
      //console.log("Draft Updated", response.data);
      //fetchPlayers(); // Refresh the player list
      //setAddDialogOpen(false);
    } catch (error) {
      console.error("Error adding player:", error);
    }
  };

  const handleAddToQueue = (player, event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the row
    setQueue((prevQueue) => {
      //console.log("queue:", prevQueue);
      // Check if the player is already in the queue
      const isPlayerInQueue = prevQueue.some(
        (queuePlayer) => queuePlayer.player === player.player
      );
      if (isPlayerInQueue) {
        return prevQueue; // Return the existing queue if the player is already in it
      }
      return [...prevQueue, player]; // Add the player to the queue if not already present
    });
  };

  const loadQueue = (queue) => {
    setQueue(queue);
  };

  // const handlePreviousPage = () => {
  //   setPage((prevPage) => Math.max(prevPage - 1, 1));
  // };

  // const handleNextPage = () => {
  //   setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  // };

  const toggleHideDrafted = () => {
    setHideDrafted(!hideDrafted);
  };

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
    setPlayerDetailsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPlayer(null);
    setPlayerDetailsModalOpen(false);
    setVorData([]);
  };

  return (
    <div className="container">
      <div className="spreadsheet">
        <div
          // className="filter-input"
          style={{
            width: "99%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          {draft_id ? <h3>{draftTitle}</h3> : <h3>Sample Draft Dashboard</h3>}
          {draft_id && (
            <button
              style={{ float: "right" }}
              onClick={(e) => {
                saveCurrentState(e);
                alert("Draft Saved!");
              }}
            >
              Save Log State
            </button>
          )}
        </div>
        <input
          type="text"
          placeholder="Filter by name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
        <b>Filters: </b>
        {/* <button
          style={{ float: "right" }}
          onClick={(e) => {
            getCurrentState(e);
            alert("Draft Saved!");
          }}
        >
          Get Current State
        </button> */}
        <button style={{ margin: "5px" }} onClick={toggleHideDrafted}>
          {hideDrafted ? "Show Drafted" : "Hide Drafted"}
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("WR")}>
          WR
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("RB")}>
          RB
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("QB")}>
          QB
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("TE")}>
          TE
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("K")}>
          K
        </button>
        <button style={{ margin: "5px" }} onClick={(e) => setFilter("DST")}>
          DST
        </button>
        <button
          style={{ margin: "5px" }}
          onClick={(e) => {
            setFilter("FLEX");
          }}
        >
          FLEX
        </button>

        <button style={{ float: "right" }} onClick={(e) => setFilter("")}>
          Clear Filter
        </button>

        {/* <div className="pagination">
          <button onClick={handlePreviousPage} disabled={page === 1}>
            Previous
          </button>
          <span>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div> */}
        <div className="rankings-dt-container">
          <span className="rankings-dt">Rankings As Of: {rankings_dt}</span>
        </div>
        <table>
          <thead>
            <tr>
              <th>My Pick</th>
              <th>Drafted</th>
              <th>Actions</th>
              <th onClick={() => handleSort("player")}>Name</th>
              <th>Position</th>
              <th onClick={() => handleSort("position_rank")}>Position Rank</th>
              <th>Team</th>
              {/**<th>Bye</th>**/}
              <th onClick={() => handleSort("points_vor")}>VOR</th>
              <th onClick={() => handleSort("tier")}>Tier</th>
              <th>Dropoff</th>
              <th onClick={() => handleSort("adp")}>ADP</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(draftedData) && draftedData.length > 0 ? (
              draftedData.map((player) => (
                <tr
                  key={player.player}
                  style={{
                    backgroundColor: pickedPlayers?.[player.player]
                      ? "#0d9605"
                      : draftedPlayers?.[player.player]
                      ? "#caf50c"
                      : queue.some(
                          (queuePlayer) => queuePlayer.player === player.player
                        )
                      ? "#f5a00c"
                      : "white",
                  }} // Updated style logic
                  onClick={() => handleRowClick(player)}
                >
                  <td>
                    <Checkbox
                      checked={pickedPlayers?.[player.player] || false}
                      onChange={() => handlePickedPlayers(player.player)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={draftedPlayers?.[player.player] || false}
                      onChange={() => handleDraftedPlayers(player.player)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <button
                        // className="button-row"
                        onClick={(e) => handlePlayerClick(player, e)}
                      >
                        Open Graph
                      </button>
                      <button
                        // className="button-row"
                        onClick={(e) => handleAddToQueue(player, e)}
                      >
                        Add to Queue
                      </button>
                    </div>
                  </td>
                  <td>
                    {player.player}{" "}
                    {player.handcuff_player && (
                      <FaLock
                        style={{ color: "red", marginLeft: "8px" }}
                        title="Handcuff"
                      />
                    )}
                  </td>
                  <td>{player.position}</td>
                  <td>{player.position_rank}</td>
                  <td>{player.team}</td>
                  {/*<td>{player.bye}</td>*/}
                  <td>{player.points_vor}</td>
                  <td>{player.tier}</td>
                  <td>{player.dropoff}</td>
                  <td>{player.adp}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="yourColumnCount">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination">
          <button onClick={handlePreviousPage} disabled={page === 1}>
            Previous
          </button>
          <span>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div> */}
        {!modalPlayerDetailsModalOpen && selectedPlayer && (
          <Dialog
            open={true}
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { width: "300px" } }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>Player Details</DialogTitle>
            <DialogContent>
              <p>Name: {selectedPlayer.player}</p>
              <p>Position: {selectedPlayer.position}</p>
              <p>Position Rank: {selectedPlayer.position_rank}</p>
              <p>Team: {selectedPlayer.team}</p>
              <p>Points: {selectedPlayer.points}</p>
              <p>Experience: {selectedPlayer.exp}</p>
              <p>Bye: {selectedPlayer.bye}</p>
              <p>VOR: {selectedPlayer.points_vor}</p>
              <p>Tier: {selectedPlayer.tier}</p>
              <p>Dropoff: {selectedPlayer.dropoff}</p>
              <p>VOR-Low: {selectedPlayer.floor_vor}</p>
              <p>VOR-High: {selectedPlayer.ceiling_vor}</p>
              <p>ADP: {selectedPlayer.adp}</p>
              <p>Handcuff: {selectedPlayer.handcuff_player}</p>
              <p>Handcuffing: {selectedPlayer.parent_player}</p>
              <p>Proj Round: {selectedPlayer.round_proj}</p>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <Queue queue={queue} setQueue={setQueue} />
      {modalPlayerDetailsModalOpen && selectedPlayer && (
        <PlayerDetailsModal
          open={modalPlayerDetailsModalOpen}
          onClose={handleCloseModal}
          playerData={selectedPlayer}
          vorData={vorData}
        />
      )}
    </div>
  );
};

export default Dashboard;
