import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Queue = ({ queue, setQueue }) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(queue);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQueue(items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-queue">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="queue"
          >
            <h3>Queue</h3>
            <ul>
              {queue.map((player, index) => (
                <Draggable
                  key={player.id}
                  draggableId={`draggable-${player.id}`}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="queue-item"
                    >
                      {player.player} ({player.position}) - {player.team} -{" "}
                      {player.adp}
                      <button
                        onClick={() =>
                          setQueue(queue.filter((_, i) => i !== index))
                        }
                      >
                        Remove
                      </button>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Queue;
