import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Lineup from "./Lineup";

// const starters_obj = {
//   "Christian McCaffrey": true,
//   "A.J. Brown": true,
//   "Ja'Marr Chase": true,
//   "Saquon Barkley": true,
//   "Breece Hall": true,
//   "Bijan Robinson": true,
//   "Justin Jefferson": true,
//   "Jalen Hurts": true,
//   "Jahmyr Gibbs": true,
//   "Garrett Wilson": true,
//   "Rachaad White": true,
// };

const MyDraft = () => {
  const [players, setPlayers] = useState([]);
  const { draft_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getDraftDetails = async () => {
      try {
        const response = await axios.get(`/api/myDraft/${draft_id}`);
        const data = response.data;
        //console.log(data.data[0]);
        setPlayers(data.data);
        if (data.data.length === 0) {
          navigate(`/home`);
        }
      } catch (error) {
        console.error("Error fetching draft data:", error);
      }
    };

    getDraftDetails();
  }, [draft_id, navigate]);

  return (
    <>
      <div>
        <h1>My Drafted Players</h1>
        {players.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Position</th>
                  <th>Team</th>
                  <th>Bye</th>
                  <th>VOR</th>
                  <th>Points (ROS)</th>
                </tr>
              </thead>
              <tbody>
                {players.map((player) => (
                  <tr key={player.player}>
                    <td>
                      {player.player}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      ></div>
                    </td>
                    {/* <td>{player.player}</td> */}
                    <td>{player.position}</td>
                    <td>{player.team}</td>
                    <td>{player.bye_week}</td>
                    <td>{player.points_vor}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Lineup players={players} />
          </>
        ) : (
          <p>No players drafted yet.</p>
        )}
      </div>
    </>
  );
};

export default MyDraft;
